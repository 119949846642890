import React from "react";
import { graphql } from "gatsby";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import quote from "../images/Meet the Team/quote.svg";

export const query = graphql`
  query TeamMemberTemplateQuery($id: String!) {
    teamMember: sanityTeamMember(id: { eq: $id }) {
      id
      name
      seoTitle
      metaDescription
      headshot {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 374)
        }
      }
      title
      emailAddress
      phoneNumber
      quote
      quoteAuthor
      _rawBio
    }
  }
`;

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const Template = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title={data.teamMember.seoTitle && data.teamMember.seoTitle}
        description={
          data.teamMember.metaDescription && data.teamMember.metaDescription
        }
        // openGraphImage={data.teamMember.openGraphImage && data.teamMember.openGraphImage.asset.url}
        // twitterOpenGraphImage={
        //   data.teamMember.twitterCardImage && data.teamMember.twitterCardImage.asset.url
        // }
      />

      <section className="mb-20 pt-8 md:mb-32">
        <div className="container">
          <div className="grid gap-y-8 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
            <div className="md:col-span-4 md:col-start-1">
              {data.teamMember.headshot && (
                <div className="md:mb-6">
                  <GatsbyImage
                    image={data.teamMember.headshot.asset.gatsbyImageData}
                    alt={data.teamMember.name}
                    loading="eager"
                  />
                </div>
              )}

              <div className="hidden md:block">
                <div className="space-y-5">
                  {data.teamMember.emailAddress && (
                    <div className="flex items-center space-x-3.5">
                      <i class="fa-solid fa-envelope text-xl text-typography-heading"></i>
                      <a
                        href={`mailto:${data.teamMember.emailAddress}`}
                        className="font-normal text-primary-500 no-underline"
                      >
                        {data.teamMember.emailAddress}
                      </a>
                    </div>
                  )}

                  {data.teamMember.phoneNumber && (
                    <div className="flex items-center space-x-3.5">
                      <i class="fa-solid fa-phone text-xl text-typography-heading"></i>
                      <a
                        href={`tel:${data.teamMember.phoneNumber
                          .replace(/[()]/g, "")
                          .replace(/ /g, "-")}`}
                        className="font-normal text-primary-500 no-underline"
                      >
                        {data.teamMember.phoneNumber}
                      </a>
                    </div>
                  )}
                </div>

                <div>
                  {(data.teamMember.quote || data.teamMember.quoteAuthor) && (
                    <div className="mt-12 md:mt-20">
                      {data.teamMember.quote && (
                        <>
                          <img
                            src={quote}
                            width={34}
                            className="mb-4 md:mb-6"
                          />
                          <q className="mb-4 block text-xl before:hidden">
                            {data.teamMember.quote}
                          </q>
                        </>
                      )}

                      {data.teamMember.quoteAuthor && (
                        <div className="text-sm font-light uppercase tracking-wide">
                          &mdash; {data.teamMember.quoteAuthor}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="md:col-span-8 md:col-end-13">
              <h1>{data.teamMember.name}</h1>
              {data.teamMember.title && (
                <div className="font-heading text-sm font-light uppercase text-gray-400">
                  {data.teamMember.title}
                </div>
              )}

              <div className="mt-8 md:hidden">
                <div className="space-y-5">
                  {data.teamMember.emailAddress && (
                    <div className="flex items-center space-x-3.5">
                      <i class="fa-solid fa-envelope text-xl text-typography-heading"></i>
                      <a
                        href={`mailto:${data.teamMember.emailAddress}`}
                        className="font-normal text-primary-500 no-underline"
                      >
                        {data.teamMember.emailAddress}
                      </a>
                    </div>
                  )}

                  {data.teamMember.phoneNumber && (
                    <div className="flex items-center space-x-3.5">
                      <i class="fa-solid fa-phone text-xl text-typography-heading"></i>
                      <a
                        href={`tel:${data.teamMember.phoneNumber
                          .replace(/[()]/g, "")
                          .replace(/ /g, "-")}`}
                        className="font-normal text-primary-500 no-underline"
                      >
                        {data.teamMember.phoneNumber}
                      </a>
                    </div>
                  )}
                </div>

                <div>
                  {data.teamMember.quote && data.teamMember.quoteAuthor && (
                    <div className="mt-12 md:mt-20">
                      <img src={quote} width={34} className="mb-4 md:mb-6" />
                      <q className="mb-4 block text-xl before:hidden">
                        {data.teamMember.quote}
                      </q>

                      <div className="text-sm font-light uppercase tracking-wide">
                        &mdash; {data.teamMember.quoteAuthor}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {data.teamMember._rawBio && (
                <div className="mt-12 md:mt-16">
                  <StyledContent>
                    <PortableText blocks={data.teamMember._rawBio} />
                  </StyledContent>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Template;
